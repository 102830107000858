<template>
<!--友情链接-->
  <div class="contentindex divMa">

    <titleDiv title="友情链接" english-title="YOUQING LIANJIE"></titleDiv>

    <div class="divWceng">
      <div v-for="(item,index) in list" @click="divherf(item.linkUrl)" class="divfor">
          <div v-if="!!item.linkLogo" class="imgdiv animated">
            <img  :src="dateStaticurl+item.linkLogo">
          </div>
          <div>{{item.linkName}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/style/css/index.css';
import {friendLinkApi} from "../api/index";
import {staticurl} from "@/utils/baseurl";
import titleDiv from "@/components/title.vue";

export default {
  components:{
    titleDiv
  },
  name: "friendLink",
  data(){
    return{
      project:this.$store.state.project,
      dateStaticurl:'',
      list:[]
    }
  },
  props:["titleEnglish","bjImgHidden"],
  beforeMount(){
    let key='friendLink';
    console.log(key)
    this.dateStaticurl = staticurl;
    //this.projeSid = this.$store.state.project.projectid;//项目id
     //sessionStorage.removeItem(key);//清空session
      const singlePageQ = window.sessionStorage.getItem(key)
      this.list=singlePageQ
      if(!(this.project.enableSession &&!!singlePageQ)){
      //判断缓存,不存在
      console.log("请求接口"+key)
      friendLinkApi({apartmentSid : this.project.projectid}).then((res) => {
        this.list=res.data.result
        window.sessionStorage.setItem(key, JSON.stringify(this.list))
      })
    }
  },
  methods:{
    divherf:function(url){
      // alert(url)
      window.open(url, '_blank')//_self
    }
  }
}
</script>

<style scoped>
.divWceng{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.divfor{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
}
.imgdiv{
  height: 100px;
  width: 100px;
  margin-bottom: 5px;
}
.imgdiv img{
  height: 100%;
  width: 100%;
  border-radius: 100%;
  box-shadow: 0 0 10px rgb(0 0 0 / 60%);
}
.contentindex{
  /*width: 100%;*/
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*margin: 60px 0;*/
}
a{
  text-decoration:none;
  font-weight: 900;
}
@media (max-width: 600px){
  .contentindex{
    /*margin: 30px 0;*/
  }
}

.animated {
  animation: shake 5s infinite;
}
@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}
</style>
