import { render, staticRenderFns } from "./templateExample.vue?vue&type=template&id=422b4681&scoped=true&"
import script from "./templateExample.vue?vue&type=script&lang=js&"
export * from "./templateExample.vue?vue&type=script&lang=js&"
import style0 from "./templateExample.vue?vue&type=style&index=0&id=422b4681&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "422b4681",
  null
  
)

export default component.exports