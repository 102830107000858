<template>
<!--  单页面组件-->
  <div>
    <div class="yqjswrap divMa" v-for="(item,index) in this.single.list" :key="index">
      <div class="yqjscontent">

        <titleDiv :title="item.singleName" english-title="COMMON PROBLEM"></titleDiv>

        <div class="yqjs_item">
          <img v-if="!!item.singleLogo"
              :class="!item.single_Content?'yqjsimgall': 'yqjsimg' +' wow animate__animated animate__fadeInLeft'"
              :src="dateStaticurl + item.singleLogo"
          />
          <div :class="!item.single_Logo?'yqjstextall':'yqjstext' +' wow animate__animated animate__fadeInRight'">
            <div v-html="item.singleContent"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/style/css/index.css';
import "swiper/swiper-bundle.css";
import {singleapi} from "@/api/zjyqapi.js";
import {staticurl} from "@/utils/baseurl";
import wow from "wowjs";
import titleDiv from "@/components/title.vue";
export default {
  components:{
    titleDiv
  },
  name: "Singlepage",
  data(){
    return{
      project:this.$store.state.project,
      dateStaticurl:"",
      projeSid:"",
      single:{
        list: []
      },
    }
  },
  props:["bjImgHidden","titleEnglish"],
  beforeMount(){
    console.log('单页面')
    this.dateStaticurl = staticurl;
    //this.projeSid = this.$store.state.project.projectid;//项目id
    //sessionStorage.removeItem('singlePage');//清空singlePage
      var key='singlePage'
    const singlePageQ = window.sessionStorage.getItem(key)

    if(!(this.$store.state.project.enableSession &&!!singlePageQ)){
      //判断缓存,不存在
      console.log("请求接口")
      singleapi({apartmentSid : this.project.projectid}).then((res) => {
        this.single.list=res.data.result
        window.sessionStorage.setItem(key, JSON.stringify(this.single.list))
      })
    }else {
      console.log("读缓存")
      this.single.list = JSON.parse(window.sessionStorage.getItem(key))
    }
  },
  mounted(){
    this.$nextTick(() => {
      new wow.WOW().init();
    });
  }
}
</script>

<style scoped>
.yqjswrap {
  /*width: 100%;*/
  /*margin: 10px 0;*/
}
.yqjscontent {
  width: 100%;
  /*margin: 0 auto;*/
  /*margin-top: 15px;*/
}
.yqjstitle {
  text-align: center;
  font-size: 30px;
  color:white;
  line-height: 100px;
  background-image: url("../assets/images/titleback.png");
  background-size: cover;
}
.yqjs_item {
  display: flex;
  align-items: center;
  /*margin-top: 10px;*/
}
.yqjsimg {
  width: 50%;
}
.yqjstext {
  line-height: 30px;
  box-sizing: border-box;
  /*padding: 30px 40px;*/
  font-size: 14px;
}
.yqjsimgall {
  width: 100%;
}
.yqjstextall {
  width: 100%;
  line-height: 30px;
  box-sizing: border-box;
  /*padding: 30px 40px;*/
  font-size: 14px;
}
>>> img{
  width: 100%;
}
>>>table{
  width: 100%;
  border-style: solid;
  border-color: #c2c1bf;
  border-width: 1px;
  border-collapse: collapse;
  border-spacing: 5px;

  color: #4f525e;
  font-weight: 500;
  font-size: 14px!important;
  margin-bottom: 50px!important;
}
>>>td{
  border-style: solid;
  border-width: 1px;
  border-color: #c2c1bf;
}
@media (max-width: 600px){
  .yqjs_item{
    flex-direction: column;
  }
  .yqjsimg{
    width: 100%;
  }
}
</style>
