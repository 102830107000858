<template>
<div class="servewrap">
  <headbox></headbox>
  <!-- 滑块 -->
  <banner positionSid="6433"
          showTitle="true"
          :title="this.$store.state.project.projectName"
          :englishName="this.$store.state.project.projectEnglishName"
          :content="this.$store.state.project.projectDesc">
  </banner>

  <!-- 申请流程  合作流程-->
  <flowListTemplate></flowListTemplate>

  <!--关于我们-->
  <gywm></gywm>

  <!--福利政策-->
  <flzc></flzc>

  <!--配套服务 isRecommended:0=否 1=推荐服务-->
  <SupportingService isRecommended="1"></SupportingService>

<!--  <SupportingService-->
<!--      isRecommended="0"-->
<!--      bjImgHidden="1"-->
<!--      :titleEnglish="this.$store.state.project.titleEnglishIsShow"></SupportingService>-->

  <!--公司理念-->
  <gsln></gsln>

  <!--产品核心  单页面组件-->
  <Singlepage></Singlepage>


  <!--热点资讯-->
  <informationNews></informationNews>

  <!-- 常见问题 -->
  <commonProblem></commonProblem>

  <friendLink></friendLink>
<!--  运营模式-->
  <yyms></yyms>
  <!-- 底部 -->
  <foot></foot>
  <!-- 底部 -->
  <bottom></bottom>
  <sidebar ref="mysiderbar"></sidebar>
</div>
</template>

<script>
import headbox from "@/components/head.vue";
import banner from "@/components/banner.vue";
import foot from "@/components/foot.vue";
import bottom from "@/components/bottom.vue";
import sidebar from '@/components/sidebar'
import SupportingService from "@/components/SupportingService.vue";
import flowListTemplate from "@/components/flowListTemplate.vue";
import gywm from "@/components/gywm.vue";
import gsln from "@/components/gsln.vue";
import flzc from "@/components/flzc.vue";
import commonProblem from "@/components/commonProblem.vue";
import Singlepage from "@/components/Singlepage.vue";
import informationNews from "@/components/informationNews.vue";
import friendLink from "@/components/friendLink.vue";
import yyms from "@/components/yyms.vue";
export default {
  components:{
    headbox,
    banner,
    foot,
    bottom,
    sidebar,
    SupportingService,
    flowListTemplate,
    gywm,
    gsln,
    flzc,
    commonProblem,
    Singlepage,
    informationNews,
    friendLink,
    yyms
  },
  name: "templateExample",
  data(){
    return{

    }
  }
}
</script>

<style scoped>
.servewrap {
  font-size: 12px;
}
</style>
